import React from 'react';
import './ThankyouSection.css';

function ThankyouSection() {
  return (
    <div className='thankyou-container'>
      <div className='thankyou-header'>
        <h1>Takk fyrir!</h1>
        <h2>Fyrirspurnin hefur verið móttekin</h2>
      </div>
      
    </div>
  );
}

export default ThankyouSection;
